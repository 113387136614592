import axios from 'axios'
import VueCookies from 'vue-cookies'
import router from '@/router'
import { Message } from 'element-ui'

axios.defaults.baseURL = 'http://localhost:8000'
if (process.env.NODE_ENV === 'production') {
  window.location.protocol
  axios.defaults.baseURL = `//${window.location.host}/api`
  axios.defaults.withCredentials = true
}
axios.defaults.headers['Content-Type'] = 'application/json'

axios.interceptors.request.use((config) => {
  // use cookie, auth header is unnecessary
  config.headers['Token'] = VueCookies.get('token')
  return config
}, (error) => {
  return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
  return res.data.data
}, (error) => {
  if (error.response.status === 401) {
    VueCookies.remove('token')
    localStorage.setItem('after-login', router.currentRoute.fullPath)
    router.replace('/sign-in')
  }
  if (!error.response) {
    Message({message: error, type: 'error'})
    return Promise.reject(error)
  }
  if (error.response.data.error.message !== '') {
    Message({message: error.response.data.error.message, type: 'warning'})
  } else {
    Message({message: error.response.data.error.code, type: 'warning'})
  }
  return Promise.reject(error)
})

let api = (() => {
  return {
    get(url, params) {
      return axios.get(url, params)
    },
    post(url, params) {
      return axios.post(url, params)
    },
    head(url, params) {
      return axios.head(url, params)
    },
    delete(url, params) {
      return axios.delete(url, {data: params})
    },
    put(url, params) {
      return axios.put(url, params)
    },
    patch(url, params) {
      return axios.patch(url, params)
    },
  }
})()

export default api
